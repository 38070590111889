<template>
  <div>
    <b-card v-if="data" no-body>
      <b-card-body>
        <app-timeline>
          <app-timeline-item
            v-for="log in timelineData"
            :key="log._id"
            :variant="log.type"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h6>{{ log.changeText }}</h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{
                getFormattedTime(log.createdAt)
              }}</small>
            </div>
            <p>Added by {{ getName(log) }}</p>
            <b-media no-body v-if="log.changedData">
              <b-button
                size="sm"
                class="mb-0"
                variant="outline-dark"
                @click="viewJsonData(log.changedData)"
                >View Data</b-button
              >
            </b-media>
          </app-timeline-item>
        </app-timeline>
      </b-card-body>
    </b-card>

    <b-modal
      v-model="showJSONdata"
      id="json-viewer-modal"
      title="JSON Data"
      hide-footer
      size="lg"
    >
      <json-viewer
        :value="logData"
        :expand-depth="5"
        copyable
        boxed
      ></json-viewer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BButton,
  BModal,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment-timezone";
import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    JsonViewer,
    BButton,
    BModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showJSONdata: false,
      logData: "",
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    timelineData() {
      let data = this.data;
      return data.reverse();
    },
  },
  methods: {
    getName(log) {
      if (log.user) {
        return log.user.name;
      } else if (log.admin) {
        return `${log.admin.firstname} ${log.admin.lastname}`;
      } else {
        return "NA";
      }
    },
    getFormattedTime(time) {
      return moment(time)
        .tz("Asia/Kolkata")
        .format("DD-MM-YYYY, h:mm a");
    },
    viewJsonData(data) {
      this.logData = data;
      this.showJSONdata = true;
    },
  },
};
</script>

<style lang="scss"></style>
