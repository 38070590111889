<template>
  <div>
    <div class="text-right mb-1">
      <b-button @click="downloadInvoice" variant="outline-dark">
        <b-spinner small v-if="downloadingInvoice"></b-spinner> Download Invoice
        <feather-icon icon="DownloadIcon"></feather-icon>
      </b-button>

      <b-button @click="showPaymentModal = true" variant="success" class="ml-1">
        Accept Payment
        <feather-icon icon="DollarSignIcon"></feather-icon>
      </b-button>
    </div>
    <b-card :title="`${$route.meta.pageTitle} - ${order.orderID}`">
      <b-row>
        <b-col cols="4" class="mb-1">
          <strong>Name : </strong> {{ order.user.name }}
        </b-col>
        <b-col cols="4" class="mb-1">
          <strong>Phone Number : </strong> {{ order.user.phone }}
        </b-col>
        <b-col cols="4" class="mb-1">
          <strong>Brand : </strong> {{ order.brand.name }}
          <b-avatar variant="light" square :src="order.brand.logo.image">
          </b-avatar>
        </b-col>
        <b-col cols="4" class="mb-1">
          <strong>Parts Total : </strong>
          <b-badge variant="light-primary"
            >Rs {{ formatPrice(order.subTotal) }}</b-badge
          >

          <div class="mt-1">
            <strong>Warranty Total : </strong>
            <b-badge variant="light-primary"
              >Rs
              {{ formatPrice(order.warrantyTotal) }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="4" class="mb-1">
          <strong>Model : </strong> {{ order.model.name }}
          <b-avatar variant="light" :src="order.model.media.image"> </b-avatar>
        </b-col>
        <b-col cols="4" class="mb-1">
          <strong>Mode : </strong>
          <b-form-select
            @change="handleModeChange"
            v-model="order.mode"
            :options="orderModeOptions"
          />

          <div class="mt-1" v-if="order.mode != 'Walk-in'">
            <b-form-select
              class="address-select"
              v-model="order.address"
              :options="addresses"
            ></b-form-select>
            <div>
              <b-button
                class="mt-1"
                @click="showAddAddressModal"
                variant="outline-primary"
                block
                >Add Address <feather-icon icon="PlusIcon"></feather-icon>
              </b-button>
            </div>
          </div>

          <div class="mt-1" v-if="order.mode != 'Walk-in'">
            <b-row>
              <b-col cols="6" class="pr-0">
                <b-form-datepicker
                  id="repair-datepicker"
                  v-model="serviceDate.date"
                  :min="minDate"
                  placeholder="Choose a date"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="6" class="pl-0">
                <b-form-timepicker
                  minutes-step="15"
                  placeholder="Choose a time"
                  v-model="serviceDate.time"
                  locale="en"
                ></b-form-timepicker>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mt-1">
            <strong>Last Updated : </strong>
            {{ getFormattedTime(order.updatedAt) }}
          </div> -->
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="9">
        <b-card title="Device Details" v-if="page === 'order'">
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Serial Number"
                class="mb-0"
                label-for="serial-number-input"
              >
                <b-form-input
                  :disabled="updatingOrder"
                  v-model="order.device.serialNumber"
                  id="serial-number-input"
                  placeholder="Enter Device Serial Number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="IMEI Number"
                class="mb-0"
                label-for="imei-number-input"
              >
                <b-form-input
                  :disabled="updatingOrder"
                  v-model="order.device.IMEInumber"
                  id="imei-number-input"
                  placeholder="Enter Device IMEI Number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Device Memory"
                class="mb-0"
                label-for="memory-number-input"
              >
                <b-form-input
                  :disabled="updatingOrder"
                  v-model="order.device.memory"
                  id="memory-number-input"
                  placeholder="Enter Device Memory (Eg: 12GB)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Device Storage"
                class="mb-0"
                label-for="storage-number-input"
              >
                <b-form-input
                  :disabled="updatingOrder"
                  v-model="order.device.storage"
                  id="storage-number-input"
                  placeholder="Enter Device Storage (Eg: 128GB)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-overlay :show="showOverlayLoader" rounded="sm">
          <b-card>
            <b-card-title>
              <div class="d-flex justify-content-between">
                <div>
                  <h4>Issues & Warranty</h4>
                </div>
                <div>
                  <b-button
                    @click="showIssueSelectionModal = true"
                    variant="outline-dark"
                    size="sm"
                    >Add Issues
                    <feather-icon icon="PlusIcon"></feather-icon>
                  </b-button>
                </div>
              </div>
            </b-card-title>
            <b-table responsive :items="order.issues" :fields="fields">
              <template #cell(issue)="data">
                <b-avatar class="mr-1" :src="data.item.issue.icon" />
                {{ data.item.issue.name }}
              </template>
              <template #cell(price)="data">
                Rs {{ formatPrice(data.item.price) }}
              </template>
              <template #cell(warranty)="data">
                <div class="">
                  <b-form-checkbox
                    v-if="isWarrantyAddonAvailable(data.item.issue._id).status"
                    :value="true"
                    v-model="
                      order.warrantyAddons[
                        isWarrantyAddonAvailable(data.item.issue._id).index
                      ].warranty.isUserSelected
                    "
                  ></b-form-checkbox>
                  <div v-else>
                    NA
                  </div>
                </div>
              </template>
              <template #cell(warranty_details)="data">
                <div
                  v-if="isWarrantyAddonAvailable(data.item.issue._id).status"
                >
                  <div>
                    <strong>Price : </strong>
                    Rs
                    {{
                      formatPrice(
                        order.warrantyAddons[
                          isWarrantyAddonAvailable(data.item.issue._id).index
                        ].warranty.price
                      )
                    }}
                  </div>
                  <div>
                    <strong>Period : </strong>
                    {{
                      order.warrantyAddons[
                        isWarrantyAddonAvailable(data.item.issue._id).index
                      ].warranty.period
                    }}
                    {{
                      order.warrantyAddons[
                        isWarrantyAddonAvailable(data.item.issue._id).index
                      ].warranty.periodValue
                    }}
                  </div>
                </div>
                <div v-else>NA</div>
              </template>
              <template #cell(action)="data">
                <b-button
                  v-b-tooltip.hover.v-danger
                  title="Remove Issue"
                  size="sm"
                  @click="removeIssue(data.item)"
                  class="btn-icon"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
                <b-button
                  @click="initPartAssignment(data.item)"
                  v-b-tooltip.hover.v-info
                  title="Assign Part"
                  size="sm"
                  class="btn-icon ml-1"
                  :variant="isPartAssigned(data.item) ? 'info' : 'outline-info'"
                >
                  <feather-icon icon="CpuIcon"></feather-icon>
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-overlay>

        <b-overlay
          v-if="partAssignments.length"
          :show="showUnAssignOverlayLoader"
          rounded="sm"
        >
          <b-card>
            <b-card-title>
              <div class="d-flex justify-content-between">
                <div>
                  <h4>Parts Assigned</h4>
                </div>
              </div>
            </b-card-title>
            <b-table
              responsive
              :items="partAssignments"
              :fields="partsAssignmentfields"
            >
              <template #cell(issue)="data">
                <b-avatar class="mr-1" :src="data.item.issue.icon" />
                {{ data.item.issue.name }}
              </template>
              <template #cell(part)="data">
                {{ data.item.inventory.name }}
              </template>

              <template #cell(action)="data">
                <b-button
                  v-b-tooltip.hover.v-danger
                  title="Unassign Issue"
                  size="sm"
                  @click="unAssignPart(data.item)"
                  class="btn-icon"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-overlay>

        <b-card title="Activity Log" class="activity-log-card">
          <Timeline :data="order.timeline" />

          <b-card-footer>
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="New Comment"
                  class="mb-0"
                  label-for="ad-comment"
                >
                  <b-form-input
                    :disabled="addingComment"
                    v-model="activityComment"
                    id="ad-comment"
                    placeholder="Enter new comment"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4" class="align-self-end">
                <b-button
                  @click="addComment"
                  :disabled="addingComment"
                  block
                  variant="dark"
                >
                  <b-spinner small v-if="addingComment"></b-spinner> Add Comment
                  <feather-icon icon="PlusIcon"></feather-icon>
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col cols="3">
        <b-card title="Status Updates">
          <b-form-group
            v-if="page === 'lead'"
            label="Order Status"
            class="mb-0 mt-2"
            label-for="order-status"
          >
            <b-form-select
              v-model="order.isDraft"
              :options="orderPublishStatusOptions"
            />
          </b-form-group>
          <b-form-group
            label="Current Order Status"
            class="mb-0 mt-2"
            label-for="current-order-status"
          >
            <b-form-select v-model="order.status" :options="statusOptions" />
          </b-form-group>
          <b-form-group
            label="Due Date"
            class="mb-0 mt-2"
            label-for="due-date-status"
          >
            <b-form-datepicker
              id="due-date-datepicker"
              v-model="order.dueDate"
              :min="minDate"
              placeholder="Choose a date"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-card>

        <div class="sticky">
          <b-card title="Receipt">
            <div
              class="selectedItem"
              v-for="issue in order.issues"
              :key="issue._id"
            >
              <div class="item-name">
                <h5>{{ issue.issue.name }}</h5>
              </div>
              <div class="item-price">
                <h5>Rs {{ formatPrice(issue.price) }}</h5>
              </div>
            </div>
            <div
              v-for="warrantyAddon in order.warrantyAddons"
              :key="warrantyAddon._id"
            >
              <div
                class="selectedItem"
                v-if="warrantyAddon.warranty.isUserSelected"
              >
                <div class="item-name">
                  <h5>{{ warrantyAddon.issue.name }} - Warranty</h5>
                </div>
                <div class="item-price">
                  <h5>Rs {{ formatPrice(warrantyAddon.warranty.price) }}</h5>
                </div>
              </div>
            </div>
            <b-card-footer class="px-0 pb-0">
              <div class="d-flex justify-content-between">
                <div>
                  <h4>Total</h4>
                </div>
                <div>
                  <h4>Rs {{ formatPrice(getTotal) }}</h4>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <h6>Total Paid</h6>
                </div>
                <div>
                  <h6>Rs {{ formatPrice(paymentDetails.totalPaid) }}</h6>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <h6>Pending Amount</h6>
                </div>
                <div>
                  <h6>Rs {{ formatPrice(paymentDetails.pendingAmount) }}</h6>
                </div>
              </div>
            </b-card-footer>
          </b-card>

          <b-card class="card-transaction" no-body>
            <b-card-header>
              <b-card-title>Transactions</b-card-title>
            </b-card-header>

            <b-card-body>
              <div
                v-for="transaction in paymentDetails.paymentsData"
                :key="transaction._id"
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="
                        transaction.mode === 'Online'
                          ? 'light-success'
                          : transaction.mode === 'Cash'
                          ? 'light-success'
                          : transaction.mode === 'POD'
                          ? 'light-warning'
                          : transaction.mode === 'QR'
                          ? 'light-success'
                          : ''
                      "
                    >
                      <feather-icon
                        size="18"
                        :icon="
                          transaction.mode === 'Online'
                            ? 'CreditCardIcon'
                            : transaction.mode === 'Cash'
                            ? 'DollarSignIcon'
                            : transaction.mode === 'POD'
                            ? 'ClockIcon'
                            : transaction.mode === 'QR'
                            ? 'GridIcon'
                            : ''
                        "
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ transaction.mode }}
                    </h6>
                    <small>{{ transaction.createdAt }}</small>
                  </b-media-body>
                </b-media>
                <div class="font-weight-bolder text-success">
                  ₹ {{ formatPrice(transaction.amount) }}
                </div>
              </div>
            </b-card-body>
          </b-card>

          <b-button
            :disabled="updatingOrder"
            size="lg"
            @click="updateOrder"
            variant="primary"
            block
          >
            <b-spinner small v-if="updatingOrder"></b-spinner> Update
            Data</b-button
          >
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="add-address-modal"
      title="Add New Address"
      no-close-on-backdrop
      centered
      ok-only
      ok-title="Save Address"
      @ok="saveAddress"
      v-model="showAddAddress"
    >
      <b-card-text>
        <b-form-group label="Full name:">
          <b-form-input
            type="text"
            v-model="address.name"
            placeholder="Enter your full name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Mobile number"
          description="May be used to assist delivery"
        >
          <b-form-input
            type="text"
            v-model="address.phone"
            placeholder="10 digit mobile number without prefixes"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="PIN code:">
          <b-form-input
            placeholder="6 digits [0-9] PIN code"
            v-model="address.pincode"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Flat, House no., Building, Company, Apartment">
          <b-form-input v-model="address.building" required></b-form-input>
        </b-form-group>

        <b-form-group label="Area, Colony, Street, Sector, Village">
          <b-form-input v-model="address.area" required></b-form-input>
        </b-form-group>

        <b-form-group label="Landmark">
          <b-form-input
            v-model="address.landmark"
            placeholder="E.g. Near AIIMS Flyover, Behind Regal Cinema, etc."
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Town/City">
          <b-form-input v-model="address.city" required></b-form-input>
        </b-form-group>

        <b-form-group label="State / Province / Region">
          <b-form-select
            v-model="address.state"
            :options="states"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Address Type">
          <b-form-select
            v-model="address.addressType"
            :options="addrTypes"
          ></b-form-select>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      v-model="showIssueSelectionModal"
      id="issue-selection-modal"
      content-class="shadow"
      title="Add Issues"
      no-fade
      ok-only
      ok-title="Add Issues"
      @ok="addIssues"
    >
      <b-card-text>
        <b-form-group label="Issues">
          <v-select
            placeholder="Select Issue(s)"
            multiple
            v-model="issues"
            :options="issuesList"
            label="name"
          >
            <template #option="{ name, icon }">
              <img :src="icon" />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      v-model="showPartAssignmentModal"
      id="part-assignment-modal"
      content-class="shadow"
      title="Assign Part"
      no-fade
      ok-only
      ok-title="Assign"
      @ok="assignPart"
    >
      <b-card-text>
        <b-form-group label="Part">
          <v-select
            placeholder="Select Part"
            v-model="selectedInventory"
            :options="inventories"
            label="name"
          >
            <template #option="{ name, stock }">
              <span> {{ name }} - {{ stock }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <capture-payment
      :showPaymentModal="showPaymentModal"
      :orderID="orderID"
    ></capture-payment>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BAvatar,
  BTable,
  BFormCheckbox,
  BCardFooter,
  BButton,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BFormTimepicker,
  BFormDatepicker,
  BModal,
  BCardText,
  BCardTitle,
  VBTooltip,
  BOverlay,
  BMediaAside,
  BMedia,
  BMediaBody,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import commaNumber from "comma-number";
import Timeline from "@/components/Timeline.vue";
import moment from "moment-timezone";
import vSelect from "vue-select";
import CapturePayment from "./CapturePayment.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BTable,
    BFormCheckbox,
    BCardFooter,
    BButton,
    Timeline,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BFormTimepicker,
    BFormDatepicker,
    BModal,
    BCardText,
    BCardTitle,
    vSelect,
    BOverlay,
    CapturePayment,
    BCardHeader,
    BCardBody,
    BMediaAside,
    BMedia,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Leads",
          to: "/leads",
        },
      ],
      fields: ["issue", "price", "warranty", "warranty_details", "action"],
      partsAssignmentfields: ["issue", "part", "action"],
      orderID: "",
      order: {
        user: {},
        brand: {
          logo: {},
        },
        model: {
          media: {},
        },
        issues: [],
        warrantyAddons: [],
        device: {},
        dueDate: "",
      },
      paymentDetails: {
        paymentsData: [],
      },
      partAssignments: [],
      timeline: [],
      activityComment: "",
      statusOptions: [
        { value: "Awaiting Courier", text: "Awaiting Courier" },
        { value: "Awaiting Device", text: "Awaiting Device" },
        { value: "Completed", text: "Completed" },
        { value: "Courier Received", text: "Courier Received" },
        { value: "Delivered", text: "Delivered" },
        { value: "In Progress", text: "In Progress" },
        { value: "On Hold", text: "On Hold" },
        { value: "Pick up scheduled", text: "Pick up scheduled" },
        { value: "Picked Up", text: "Picked Up" },
        { value: "Processed", text: "Processed" },
      ],
      orderPublishStatusOptions: [
        { value: true, text: "Draft" },
        { value: false, text: "Published" },
      ],
      orderModeOptions: [
        { value: "Walk-in", text: "Walk In" },
        { value: "Pickup & Drop", text: "Pickup & Drop" },
        { value: "Doorstep", text: "Doorstep" },
      ],
      updatingOrder: false,
      addingComment: false,
      page: null,
      serviceDate: {
        date: "",
        time: "",
      },
      addresses: [],
      showAddAddress: false,
      address: {
        name: "",
        phone: "",
        pincode: "",
        building: "",
        area: "",
        landmark: "",
        city: "",
        state: "",
        addressType: "",
      },
      addrTypes: ["Home", "Office/Commercial"],
      states: [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
      showIssueSelectionModal: false,
      issueListResponse: [],
      issuesList: [],
      issues: [],
      showPartAssignmentModal: false,
      showOverlayLoader: false,
      showUnAssignOverlayLoader: false,
      selectedInventory: [],
      inventories: [],
      partAssignment: {
        issue: "",
      },
      downloadingInvoice: false,
      showPaymentModal: false,
    };
  },
  watch: {
    issues(newValue) {
      // console.log(newValue);
    },
  },
  computed: {
    minDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return new Date(today);
    },
    getTotal() {
      let total = 0;
      for (let i = 0; i < this.order.issues.length; i++) {
        var { price } = this.order.issues[i];
        total = total + price;
      }
      for (let i = 0; i < this.order.warrantyAddons.length; i++) {
        var warrantyAddon = this.order.warrantyAddons[i];
        if (warrantyAddon.warranty.isUserSelected) {
          total = total + warrantyAddon.warranty.price;
        }
      }
      return total;
    },
  },
  methods: {
    delay(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    isPartAssigned(issue) {
      let issueIndex = this.partAssignments.findIndex((partAssignment) => {
        return partAssignment.issue._id == issue.issue._id;
      });
      if (issueIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    createNewAddress(address) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/address/create-address`,
            {
              user: this.order.user._id,
              address: address,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject();
          });
      });
    },
    async saveAddress(bvModalEvt) {
      try {
        bvModalEvt.preventDefault();
        if (
          this.address.name &&
          this.address.phone &&
          this.address.pincode &&
          this.address.building &&
          this.address.area &&
          this.address.city &&
          this.address.state
        ) {
          this.address.phone = `+91${this.address.phone}`;
          const { name, building, pincode } = this.address;
          await this.createNewAddress(this.address);
          this.addresses.push({
            value: this.address,
            text: `${name}, ${building}, ${pincode}`,
          });
          this.order.address = this.address;
          this.showAddAddress = false;
        } else {
          this.makeToast(
            "danger",
            "Error",
            `Please fill all the required fields!`
          );
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Error",
          `Error while adding address! Please try again later.`
        );
      }
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    showAddAddressModal() {
      this.showAddAddress = true;
    },
    getAddress() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/address`,
          {
            user: this.order.user._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let addresses = [];
          for (let i = 0; i < response.data.length; i++) {
            const address = response.data[i];
            delete address.user;
            if (this.order.address && !this.order.address.createdAt) {
              delete address.createdAt;
            }
            delete address.updatedAt;
            delete address._id;
            delete address.__v;
            const { name, building, pincode } = address;
            addresses.push({
              value: address,
              text: `${name}, ${building}, ${pincode}`,
            });
          }
          this.addresses = addresses;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", `Error getting address!`);
        });
    },
    getFormattedTime(time, format = "DD-MM-YYYY, h:mm a") {
      return moment(time)
        .tz("Asia/Kolkata")
        .format(format);
    },
    formatPrice(price) {
      return commaNumber(price);
    },
    isWarrantyAddonAvailable(issueID) {
      let warrantyAddons = this.order.warrantyAddons;
      let warrantyIndex = warrantyAddons.findIndex((warrantyAddon) => {
        return warrantyAddon.issue._id == issueID;
      });
      if (warrantyIndex < 0) {
        return { status: false };
      }

      return { status: true, index: warrantyIndex };
    },
    formatServiceDate(date, time) {
      // date = moment(date).format("YYYY-MM-DD");
      // time = moment(time).format("HH:mm:ss");
      const combinedDateTimeStr = `${date}T${time}`;
      const generatedDate = new Date(combinedDateTimeStr);
      return generatedDate.toISOString();
    },
    handleModeChange(changedValue) {
      if (changedValue != "Walk-in") {
        this.getAddress();
      }
    },
    getOrderDetails() {
      let orderID = this.orderID;
      if (!orderID) {
        return;
      }
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/order?orderID=${orderID}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.leads &&
            response.data.leads.length
          ) {
            this.order = response.data.leads[0];
            if (!this.order.dueDate) {
              this.order.dueDate = "";
            }
            this.partAssignments = response.data.partAssignments;
            this.paymentDetails = response.data.paymentDetails;
            if (this.order.mode != "Walk-in") {
              this.getAddress();
            }
            if (this.order.serviceDate) {
              this.serviceDate = {
                date: this.getFormattedTime(
                  this.order.serviceDate,
                  "YYYY-MM-DD"
                ),
                time: this.getFormattedTime(this.order.serviceDate, "HH:mm:ss"),
              };
            }

            this.getOptions();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", `Error getting the data!`);
        });
    },
    addComment() {
      this.addingComment = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/add-comment`,
          {
            comment: this.activityComment,
            orderID: this.orderID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.addingComment = false;
          this.makeToast("success", "Success", `Comment added successfully!`);
          this.order.timeline.push(response.data);
          this.activityComment = "";
        })
        .catch((error) => {
          this.addingComment = false;
          console.log(error.response.data);
          this.makeToast("danger", "Error", `Error adding comment!`);
        });
    },
    updateOrder() {
      this.updatingOrder = true;
      let orderData = this.order;
      if (
        orderData.mode != "Walk-in" &&
        this.serviceDate.date &&
        this.serviceDate.time
      ) {
        let updatedServiceDate = this.formatServiceDate(
          this.serviceDate.date,
          this.serviceDate.time
        );
        orderData.serviceDate = updatedServiceDate;
      }

      // Check for required time, date, and address in case the mode is not walk in
      if (
        orderData.mode != "Walk-in" &&
        (!this.serviceDate.date || !this.serviceDate.time)
      ) {
        return this.makeToast(
          "danger",
          "Error",
          `Select service date and time to continue!`
        );
      } else if (
        orderData.mode != "Walk-in" &&
        (!orderData.address || orderData.address === null)
      ) {
        return this.makeToast(
          "danger",
          "Error",
          `Select an address to continue!`
        );
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/update-order`,
          {
            updates: orderData,
            orderID: this.orderID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.updatingOrder = false;
          if (response.data.status) {
            this.makeToast("success", "Success", `Order updated successfully!`);
            await this.delay(1000);
            window.location.reload();
          } else {
            this.makeToast("danger", "Error", `Error updating the order!`);
          }
        })
        .catch((error) => {
          this.updatingOrder = false;
          console.log(error.response.data);
          this.makeToast("danger", "Error", `Error updating the order!`);
        });
    },
    addIssues(bvModalEvt) {
      bvModalEvt.preventDefault();
      let issues = this.issues.map((issue) => {
        let data = {
          issue: {
            _id: issue.issue,
          },
          price: issue.price,
        };

        return data;
      });

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/add-order-issues`,
          {
            updates: {
              issues: issues,
              warrantyAddons: this.order.warrantyAddons,
            },
            orderID: this.orderID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.updatingOrder = false;
          if (response.data.status) {
            this.makeToast("success", "Success", `Order updated successfully!`);
            await this.delay(1000);
            window.location.reload();
          } else {
            this.makeToast("danger", "Error", `Error updating the order!`);
          }
        })
        .catch((error) => {
          this.updatingOrder = false;
          console.log(error.response.data);
          this.makeToast("danger", "Error", `Error updating the order!`);
        });

      this.issues = [];
      this.updateAvailableIssues(this.issueListResponse);
    },
    removeIssue(issue) {
      if (confirm("Are you sure want to remove the issue?")) {
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/order/remove-order-issue`,
            {
              issue: issue.issue._id,
              orderID: this.orderID,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then(async (response) => {
            if (response.data.status) {
              this.makeToast(
                "success",
                "Success",
                `Order updated successfully!`
              );
              await this.delay(800);
              window.location.reload();
            } else {
              this.makeToast("danger", "Error", `Error updating the order!`);
            }
          })
          .catch((error) => {
            this.updatingOrder = false;
            console.log(error.response.data);
            this.makeToast("danger", "Error", `Error updating the order!`);
          });
      }
    },
    updateAvailableIssues(issues) {
      let selectedIssues = this.order.issues;
      let availableIssues = [];
      for (let i = 0; i < issues.length; i++) {
        const issue = issues[i];
        let index = selectedIssues.findIndex((selectedIssue) => {
          return selectedIssue.issue._id == issue.issue._id;
        });
        if (index < 0) {
          availableIssues.push({
            issue: issue.issue._id,
            price: issue.price,
            strikeThroughPrice: issue.strikeThroughPrice,
            icon: issue.issue.icon,
            name: issue.issue.name,
            warranty: issue.warranty,
            isPrivate: issue.isPrivate,
          });
        }
      }

      this.issuesList = availableIssues;
    },
    getOptions() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/model/get-options?model=${this.order.model._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const { modelIssues } = response.data;
          this.issueListResponse = modelIssues.issues;
          this.updateAvailableIssues(modelIssues.issues);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    getInventories() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_SERVER_URL}/api/inventory?brand=${this.order.brand._id}&model=${this.order.model._id}`,
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            this.inventories = response.data;
            resolve();
          })
          .catch((error) => {
            console.log(error.response.data);
            this.inventories = [];
            reject();
          });
      });
    },
    async initPartAssignment(issue) {
      let issueID = issue.issue._id;
      this.showOverlayLoader = true;
      this.partAssignment = {
        issue: issueID,
      };

      // get latest inventory data;
      this.getInventories()
        .then(() => {
          this.showOverlayLoader = false;
          this.showPartAssignmentModal = true;
        })
        .catch(() => {
          this.showOverlayLoader = false;
        });
    },
    assignPart() {
      let data = {
        issueID: this.partAssignment.issue,
        orderID: this.order._id,
        partID: this.selectedInventory._id,
      };
      this.showOverlayLoader = true;

      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/order/assign-part`, data, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then(async () => {
          this.showOverlayLoader = false;
          this.makeToast("success", "Success", `Part assigned successfully!`);
          await this.delay(1000);
          window.location.reload();
        })
        .catch(async (error) => {
          this.showOverlayLoader = false;
          if (error.errors) {
            this.$displayErrors(error.errors);
            return;
          }
          this.makeToast("danger", "Error", `Error assigning the part!`);
        });
    },
    unAssignPart(data) {
      if (confirm("Are you sure want to unassign this issue?")) {
        let assignmentData = {
          partID: data.inventory._id,
          orderID: data.order,
          issueID: data.issue._id,
        };
        this.showUnAssignOverlayLoader = true;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/order/unassign-part`,
            assignmentData,
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then(async () => {
            this.showUnAssignOverlayLoader = false;
            this.makeToast(
              "success",
              "Success",
              `Part unassigned successfully!`
            );
            await this.delay(1000);
            window.location.reload();
          })
          .catch(async (error) => {
            this.showUnAssignOverlayLoader = false;
            this.makeToast("danger", "Error", `Error unassigning the part!`);
            await this.delay(1000);
          });
        console.log(assignmentData);
      }
    },
    downloadInvoice() {
      this.downloadingInvoice = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/order/generate-invoice/${this.orderID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.downloadingInvoice = false;
          if (response.data.status) {
            const downloadUrl = `${process.env.VUE_APP_SERVER_URL}/api/order/download/invoice/${response.data.invoice}`;
            const anchor = document.createElement("a");
            anchor.href = downloadUrl;
            anchor.target = "_blank";
            anchor.download = "inventory-template-file.xlsx";
            anchor.click();
          }
        })
        .catch((error) => {
          this.downloadingInvoice = false;
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.orderID = this.$route.params.orderID;
    if (this.$route.name === "job-sheet-item-view") {
      this.page = "order";
    } else {
      this.page = "lead";
    }
    this.getOrderDetails();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.selectedItem {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.sticky {
  position: sticky;
  top: 100px;
}
</style>
