var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"hide-footer":"","no-close-on-backdrop":"","id":"accept-payment-modal","content-class":"shadow","title":"Accept Payment","no-fade":"","ok-only":"","ok-title":"Accept"},model:{value:(_vm.showPaymentModal),callback:function ($$v) {_vm.showPaymentModal=$$v},expression:"showPaymentModal"}},[_c('b-card-text',[_c('validation-observer',{ref:"newUserForm"},[_c('b-form-group',{attrs:{"label":"Payment Method"}},[_c('v-select',{attrs:{"placeholder":"Select Payment Method","options":['Cash', 'QR Code', 'Payment Link']},model:{value:(_vm.newPayment.selectedPaymentMethod),callback:function ($$v) {_vm.$set(_vm.newPayment, "selectedPaymentMethod", $$v)},expression:"newPayment.selectedPaymentMethod"}})],1),_c('b-form-group',{attrs:{"label":"Amount"}},[_c('validation-provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Amount"},model:{value:(_vm.newPayment.amount),callback:function ($$v) {_vm.$set(_vm.newPayment, "amount", $$v)},expression:"newPayment.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.newPayment.qrImage)?_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"qr-image",style:({ backgroundImage: 'url(' + _vm.newPayment.qrImage + ')' })})]):_vm._e(),(_vm.newPayment.selectedPaymentMethod === 'Cash')?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"disabled":!_vm.newPayment.amount,"variant":"primary"},on:{"click":_vm.capturePayment}},[(_vm.loading.creatingCashPayment)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Capture Payment")],1)],1):_vm._e(),(
          _vm.newPayment.selectedPaymentMethod === 'QR Code' &&
            !_vm.newPayment.qrImage
        )?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"disabled":!_vm.newPayment.amount,"variant":"primary"},on:{"click":_vm.generateQRcode}},[(_vm.loading.creatingQrCode)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Generate QR Code")],1)],1):_vm._e(),(
          _vm.newPayment.selectedPaymentMethod === 'QR Code' && _vm.newPayment.qrImage
        )?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"disabled":!_vm.newPayment.amount,"variant":"primary"},on:{"click":_vm.verifyQRpayment}},[(_vm.loading.verifyingQrPayment)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Verify Payment Status")],1)],1):_vm._e(),(_vm.newPayment.selectedPaymentMethod === 'Payment Link')?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"disabled":!_vm.newPayment.amount,"variant":"primary"},on:{"click":_vm.capturePayment}},[_vm._v("Generate Payment Link")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }