<template>
  <div>
    <b-modal
      hide-footer
      no-close-on-backdrop
      v-model="showPaymentModal"
      id="accept-payment-modal"
      content-class="shadow"
      title="Accept Payment"
      no-fade
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <validation-observer ref="newUserForm">
          <b-form-group label="Payment Method">
            <v-select
              placeholder="Select Payment Method"
              v-model="newPayment.selectedPaymentMethod"
              :options="['Cash', 'QR Code', 'Payment Link']"
            >
            </v-select>
          </b-form-group>
          <b-form-group label="Amount">
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <b-form-input v-model="newPayment.amount" placeholder="Amount">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>

        <div v-if="newPayment.qrImage" class="my-1">
          <div
            class="qr-image"
            :style="{ backgroundImage: 'url(' + newPayment.qrImage + ')' }"
          ></div>
        </div>

        <div
          v-if="newPayment.selectedPaymentMethod === 'Cash'"
          class="text-center"
        >
          <b-button
            :disabled="!newPayment.amount"
            @click="capturePayment"
            variant="primary"
          >
            <b-spinner small v-if="loading.creatingCashPayment"></b-spinner>
            Capture Payment</b-button
          >
        </div>
        <div
          v-if="
            newPayment.selectedPaymentMethod === 'QR Code' &&
              !newPayment.qrImage
          "
          class="text-center"
        >
          <b-button
            :disabled="!newPayment.amount"
            @click="generateQRcode"
            variant="primary"
            ><b-spinner small v-if="loading.creatingQrCode"></b-spinner>
            Generate QR Code</b-button
          >
        </div>
        <div
          v-if="
            newPayment.selectedPaymentMethod === 'QR Code' && newPayment.qrImage
          "
          class="text-center"
        >
          <b-button
            :disabled="!newPayment.amount"
            @click="verifyQRpayment"
            variant="primary"
            ><b-spinner small v-if="loading.verifyingQrPayment"></b-spinner>
            Verify Payment Status</b-button
          >
        </div>
        <div
          v-if="newPayment.selectedPaymentMethod === 'Payment Link'"
          class="text-center"
        >
          <b-button
            :disabled="!newPayment.amount"
            @click="capturePayment"
            variant="primary"
            >Generate Payment Link</b-button
          >
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import vSelect from "vue-select";
import { required } from "@validations";

export default {
  components: {
    vSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BCardText,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      required,
      newPayment: {
        selectedPaymentMethod: "",
        amount: "",
        qrID: null,
        qrImage: null,
      },
      loading: {
        creatingCashPayment: false,
        creatingQrCode: false,
        verifyingQrPayment: false,
      },
    };
  },
  props: {
    showPaymentModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    orderID: {
      type: String,
      required: true,
    },
  },
  methods: {
    delay(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createCashPayment(paymentData) {
      this.loading.createCashPayment = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/capture-payment/cash`,
          paymentData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.loading.createCashPayment = false;
          this.makeToast(
            "success",
            "Payment Captured",
            "Payment has been successfully captured!"
          );
          await this.delay(1000);
          window.location.reload();
        })
        .catch((error) => {
          this.loading.createCashPayment = false;
          this.makeToast("danger", "Error", "Error capturing payment!");
          console.log(error);
        });
    },
    generateQRcode() {
      return new Promise((resolve, reject) => {
        this.loading.creatingQrCode = true;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/order/create-qr-code`,
            {
              orderID: this.orderID,
              amount: this.newPayment.amount,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            this.newPayment.qrImage = response.data.image_url;
            this.newPayment.qrID = response.data.id;
            this.loading.creatingQrCode = false;
          })
          .catch((error) => {
            console.log(error.response);
            this.loading.creatingQrCode = false;
          });
      });
    },
    async capturePayment() {
      let payment = this.newPayment;
      if (payment.selectedPaymentMethod === "Cash") {
        await this.createCashPayment({
          paymentID: new Date().valueOf(),
          amount: this.newPayment.amount,
          orderID: this.orderID,
        });
      }
    },
    verifyQRpayment() {
      this.loading.verifyingQrPayment = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/verify-qr-payment`,
          {
            orderID: this.orderID,
            amount: this.newPayment.amount,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.loading.verifyingQrPayment = false;
          let verificationData = response.data;
          if (verificationData.status) {
            this.makeToast(
              "success",
              "Payment Captured",
              "Payment has been successfully captured!"
            );
            await this.delay(1000);
            window.location.reload();
          } else {
            this.makeToast(
              "warning",
              "Pending",
              "Payment has not been captured yet! Please try again later."
            );
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loading.verifyingQrPayment = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.qr-image {
  height: 350px;
  width: 70%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
